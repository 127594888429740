<template>
  <div>
    <CRow>
        <CCol lg="12" style="margin-bottom:10px">
            <CButton type="submit" size="sm" class="float-left btn-primary" @click="$router.go(-1)" ><CIcon name="cil-list"/> 목록</CButton>
            <CButton type="submit" size="sm" color="primary" class="float-right" @click="_validation()"><CIcon name="cil-check-circle"/> 저장</CButton>
        </CCol>
    </CRow>

    <CRow>
        <CCol lg="12">
         <CCard> 
             <CCardBody>
                 <CForm>
                    <CInput
                        v-model="inputData.groupNm"
                        label="권한 그룹명"
                        horizontal
                        invalid-feedback="권한 그룹명을 입력해주세요."
                        :is-valid="inputData.groupNm != ''"
                    />
                    <CInput
                        v-model="inputData.groupDesc"
                        label="비고"
                        horizontal
                    />
                 </CForm>
             </CCardBody>
         </CCard>
        </CCol>
    </CRow>
    <CRow>
        <AppPermissionModal v-if="isOpenAppPermissionModal" @close-modal="isOpenAppPermissionModal=false" @add-item-event="addAppPermissionItem" :chkAppKeyList="appKeyList"/>
        <CCol lg="12">
            <CCard>
                <CCardHeader>
                    <strong> 앱 제어 권한 </strong>
                    <CButton
                        type="submit"
                        size="sm"
                        variant="ghost"
                        class="float-right btn-primary btn-lft-margin"
                        @click="isOpenAppPermissionModal=true"
                        :disabled="allAppChecked==true"
                    >
                        <CIcon name="cil-plus"/>
                    </CButton>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol lg="12" style="margin-bottom:10px">
                            <CInputCheckbox
                                    label="모든 앱"
                                    value="all"
                                    class="float-right"
                                    :checked="allAppChecked"
                                    @change="checkedAllApp($event.target.checked)"
                                    :custom="true"
                            />
                        </CCol>
                    </CRow>
                    <List ref="list"
                        :items.sync=appPermissionItems
                        :fields=appFields
                        :itemsPerPage=20
                    > 
                        <template #no="{index}">
                            <td class="py-2" >
                                 {{appPermissionItems.length - index}}
                            </td>
                        </template>
                        <template #status="{item}">
                            <td>
                           <CButton 
                                    type="submit"
                                    size="sm"
                                    variant="ghost"
                                    class="btn-primary"
                                    @click="removeAppPermissionItem(item.appKey)">
                                    <CIcon name="cil-minus"/>
                            </CButton>
                            </td>
                        </template>
                    </List> 
                </CCardBody>
            </CCard>
            
            <CCard>
                <CCardHeader>
                    <strong> 메뉴 접근 권한 </strong>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol lg="12" style="margin-bottom:10px">
                            <CInputCheckbox
                                    label="모든 권한"
                                    value="all"
                                    class="float-right"
                                    :checked="allMenuChecked"
                                    @change="checkedAllMenu($event.target.checked)"
                                    :custom="true"
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="4" v-for="(item, index) in menuInfo" :key="index">
                            <CRow form class="form-group" >
                                <CCol tag="label" sm="3" class="col-form-label font-weight-bold">
                                   {{item.menuNm}}
                                </CCol>
                            </CRow>
                            <CRow form class="form-group" v-for="(subItem, subIndex) in item.childrenItems" :key="subIndex">
                                <CCol sm="3" v-if="subItem.menuKey!=102">
                                   {{subItem.menuNm}}
                                </CCol>
                                <CInputCheckbox
                                    :id="subItem.menuKey"
                                    label="Read"
                                    :inline="true"
                                    :checked="subItem.readYn==1"
                                    value="readYn"
                                    @change="checkedMenu($event)"
                                    v-if="subItem.menuKey!=102"
                                    :custom="false"
                                    />
                                <CInputCheckbox
                                    :id="subItem.menuKey"
                                    label="Write"
                                    :checked="subItem.writeYn==1"
                                    value="writeYn"
                                    @change="checkedMenu($event)"
                                    v-if="item.menuKey!=101"
                                    :custom="false"
                                />
                                
                            </CRow>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
  </div>
</template>
<script>
import List from '@/views/common/List'
import AppPermissionModal from './AppPermissionModal'
export default {
  name: 'RegistPermissionGroup',
  components:{
    List,
    AppPermissionModal    
  },
  data () {
    return {
        allAppChecked: false,
        allMenuChecked: false,
        isOpenAppPermissionModal: false,
        // 그리드 컬럼 설정 
        appFields:[
            { key: 'no', label:'No', _style: { width: '1%'} },
            { key: 'appNm', label:'앱 이름', _style: { width: '20%'} },
            { key: 'packageNm', label:'패키지명', _style: { width: '20%'} },
            { key: 'appVersion', label:'버전',  _style: { width: '20%'} },
            { key: 'osType', label:'OS', _style: { width: '20%'}, iconYn: true},
            { key: 'storeYn', label:'스토어', _style: {width: '8%'}, codeYn: true},
            { key: 'status',label:'', _style: { width: '20%'} }
        ],
        inputData: {
            groupNm: '',
            groupDesc: '',
            menuPermissionInfo:[]
        },
        allAppPermissionYn:'0',
        allMenuPermissionYn:'0',
        appPermissionItems: [],
        appKeyList: [],
        
    }
  }, 
  props: {

    },
  computed:{
      menuInfo(){
          return this.$store.state.permissionGroup.menuPermissionInfo || []
      }
  },
  created () {
  },
  mounted(){
      this.getMenuInfo()
  },
  methods: {
    checkedAllApp(checked){
        let checkedVal = '0'
        checked ? checkedVal = '1' : '0'
        if(checked){
            this.appPermissionItems = []
        }
        this.allAppPermissionYn =checkedVal
        this.allAppChecked = checked
        this.appKeyList = []
    },
    checkedAllMenu(checked){
        this.allMenuPermissionYn = checked ? '1' : '0'
        this.allMenuChecked = checked
        this.$store.commit('permissionGroup/checkedAllMenu', checked)
    },
    checkedMenu(event){
        this.$store.commit('permissionGroup/checkedMenu', event.target)
        let allReadMenuCnt = 0;
        let allWriteMenuCnt = 0;
        let readCheckedCnt = 0;
        let writeCheckedCnt = 0;
        for(let i in this.menuInfo){
            for(let j in this.menuInfo[i]['childrenItems']){
                if(this.menuInfo[i]['childrenItems'][j].readYn)
                    allReadMenuCnt++
                if(this.menuInfo[i]['childrenItems'][j].writeYn)
                    allWriteMenuCnt++
                if(this.menuInfo[i]['childrenItems'][j].readYn == '1')
                   readCheckedCnt++ 
                if(this.menuInfo[i]['childrenItems'][j].writeYn == '1')
                   writeCheckedCnt++
            }
        }
        if((allReadMenuCnt+allWriteMenuCnt) == (readCheckedCnt+writeCheckedCnt)){
            this.allMenuChecked = true
            this.allMenuPermissionYn = '1'
        }
        else {
            this.allMenuChecked = false
            this.allMenuPermissionYn = '0'
        }
    },
    addAppPermissionItem(data){
        this.appPermissionItems = data
        this.isOpenAppPermissionModal = false
    },
    removeAppPermissionItem(appKey){
        for(var i in this.appPermissionItems){
            if(this.appPermissionItems[i].appKey == appKey)
                this.appPermissionItems.splice(i, 1)
        }
    },
    async getMenuInfo(){
        await this.$store.dispatch('permissionGroup/getMenuPermissionInfo')
            
    },
    _validation(){
        if(this.inputData.groupNm == ''){
            alert('권한그룹명을 입력해주세요.')
            return false
        }
        let chkReadPermissionCnt = 0
        for(let i in this.menuInfo){
            for(let j in this.menuInfo[i]['childrenItems']){
                if(this.menuInfo[i]['childrenItems'][j].readYn == '1')
                   chkReadPermissionCnt++ 
            }
        }
        if(chkReadPermissionCnt == 0){
            alert('메뉴권한의 읽기권한은 하나 이상 존재하여야 합니다.')
            return false
        }
        
        this.save()
    },
    async save(){
         let payload ={
             groupNm: this.inputData.groupNm,
             groupDesc: this.inputData.groupDesc,
             allAppPermissionYn : this.allAppPermissionYn,
             allMenuPermissionYn : this.allMenuPermissionYn,
             menuPermissionInfoList : this.menuInfo,
             appPermissionInfoList : this.appPermissionItems
         }

        //checked menuKey
         let confirmMsg='권한을 등록하시겠습니까?'
         if(confirm(confirmMsg)){
             try {
                 let response = await this.$store.dispatch('permissionGroup/registerPermissionGroup', payload)
                 if(response.status == 200){
                     alert('등록을 완료했습니다.')
                     this.$router.push({name: 'PermissionGroupList'})
                 }
             }catch(error){
                 this.$response(error)
             }
         }
    }
  }
}
</script>
